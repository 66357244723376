/* Provide sufficient contrast against white background */

a {
    color: #0366d6;
  }
  
  code {
    color: #E01A76;
  }
  
  .btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
  }
  .footer{
    bottom:0;
    text-align: center;
    position:fixed;
    width:100%;
    background-color: #006610;
    color:white;
    margin-top:10rem;
  }
  h1{
    text-align:center;
    width: 100%;
  }
    h2{
    border-bottom: solid 2px red;
    text-align: center;
    width:100%;
    line-height: 0.1em;
    margin:20px 0 20px;
  }
  h2 span{
    background:white;
    padding:0 10px;
  }
  .address{
    text-align:left;
    width:60%;
    margin-left:50%;
  }
@media screen and (max-width:480px){
  .address{
    text-align:left;
    width:50%;
    margin-left:25%;
  }
}
  .eventStamp{
    border:solid .25rem red;
    border-radius:0.5rem;
  }

  .textSection{
  padding-top:25px;
  }
  .textSection p{
    font-family: Arial;
    font-size: 1rem;
  }
  .textSection .title{
    font-size:2rem;
    font-variant-caps: all-small-caps;
    text-align: center;
    font-weight: bold;
    color:#236cc0;
  }
  .subTitle{
    font-size:1rem;
    font-variant-caps: all-small-caps;
    text-align: center;
    font-weight: bolder;
  }
  .catalog{
    text-align:center;
    width:100%;
    padding-top:20px;
    padding-bottom:20px;
  }
  .line{
    width:75%;
    height:2px;
    border-top: solid 1px black;
    margin-left:12%;
  }
  .divider{
    width:100%;
    text-align: center;
  }
  .assoclogo{
    width:100px;
  }
  .center{
    text-align: center;
    align-items: center;
  }