.animal{
    padding-top:1rem;
}
.animal table{
   border-spacing: 0;
}
.animal td{
    padding-top:0;
    padding-bottom:0;
    margin-top:0;
    margin-bottom:0;
}
.animalLot{
    font-size:2.5rem;
    font-weight:bold;
    text-align: center;
    padding-left:1rem;
    padding-right:1rem;
    vertical-align: top;
    width:10%;
}
.animal .idRow{
    border-bottom:solid 1px black;
    width:100%;
    padding-bottom: 0;
    margin-bottom: 0;
}
.animalName{
    font-weight: bold;
    font-size: 1rem;
    width:80%;
}
.animal .mainTable{
    width:100%;
    margin-left:1 rem;
    margin-bottom:10px;
    padding-left:1rem;
}
.icon{
    width:50px;
}
.animal .dr2{
    font-size:0.75rem;
    font-weight:bold;
    vertical-align: top;
    border-bottom: solid 1px black;
}
.animal .sman{
    width:200%;
    text-align: right;
}
.dt1{
    width:100%;
    border:solid 1px black;
    margin-left:1rem;
}
.dt1 td{
    border:solid 1px black;
    text-align:center;
}
.dt1 th{
    border:solid 1px black;
    text-align:center;
}
.dt1 .rankRow{
    background-color: #cccccc;
}
.dt2{
    width:100%;
    margin-left:1rem;
}
.dt2 td{
    border-right:solid 1px black;
    text-align: center;
}
.dt2 .header{
    font-size:0.6rem;
    font-weight:bold;
}
.dt2 .data{
    font-size:0.75rem;
    font-weight:bold;
}
.dt3 {
    margin-left:1rem;
    padding-bottom:20px;
    margin-bottom:20px;
}
.dt3 .desc{
    width:75%;
    font-size:0.75rem;
}
.dt3 .logo{
    width:25%;
}
.oos{
    color:red;
    font-weight: bold;
    font-size:1.5rem;
    margin-left:50%;
}
.logo{
    vertical-align:middle;
    text-align: center;
}