.banner{
    max-height: 300px;
    height:300px;
    width:100%;
}
.headerDiv{
    background-image: url('/public/images/banner2.png');
    max-height:300px;
    overflow:hidden;
    text-align:center;
}
.headerText{
    text-align: center;
}
@media screen and(max-width:376px){
    .headerText{
        width:60%;
    }

}