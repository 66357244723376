.contactHeader{
    font-size:2rem;
    text-align: center;
    margin-top:0;
    padding-top:0;
}
.contactCard{
    text-align:left;
    border:solid 1px black;
    margin:auto;
}
.pd-top-0{
    padding-top:0;
    margin-top:0;
}
.contactInfo{
text-align:center;
}
.flexible{
    display:flex;
}
@media (max-width:415px){
    .contactCardLeft{
        float:none;
        }
        .contactCardRight{
            float:none;
        }    
}
@media (min-width:415px){
.contactCardLeft{
float:left;
}
.contactCardRight{
    float:right;
}
}
.right{
    text-align:right;
}
.sof{
    width:200px;
text-align:center;
}